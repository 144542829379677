/* storybook-check-ignore */
import React from 'react';

const ArrowRightEero: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9877 12.0965C9.40593 12.6782 8.4627 12.6782 7.88094 12.0965C7.29917 11.5147 7.29917 10.5715 7.88094 9.9897L8.3623 9.50834H2.95684C2.1341 9.50834 1.46713 8.84137 1.46713 8.01863C1.46713 7.19589 2.1341 6.52892 2.95684 6.52892L8.39961 6.52892L7.88094 6.01025C7.29917 5.42848 7.29917 4.48525 7.88094 3.90349C8.4627 3.32172 9.40593 3.32172 9.9877 3.90349L13.0308 6.94659C13.6126 7.52836 13.6126 8.47159 13.0308 9.05336L9.9877 12.0965Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRightEero;
