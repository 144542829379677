import React from 'react';

import { Flex, FlexProps, ImageProps, Text, TextProps } from '@opendoor/bricks/core';

import PressDetail, { IPressDetail } from './PressDetail';

export const DEFAULT_PRESS_ITEMS: Array<IPressDetail> = [
  {
    src: 'https://images.opendoor.com/source/s3/imgdrop-production/85d5b06bee184e76bb9aeb5cfc8aa240.png?preset=square-2048&auto=format,compress&ch=Width,DPR&dpr=2&fit=max&w=1024',
    alt: 'The New York Times',
    description: '"Moving as simple as the click of a button"',
    width: '167px',
    height: '24px',
  },
  {
    src: 'https://images.opendoor.com/source/s3/imgdrop-production/e88686bdd6aa4706ad0d7e98b00ec677.png?preset=square-2048&auto=format,compress&ch=Width,DPR&dpr=2&fit=max&w=1024',
    alt: 'Forbes',
    description: '"Changing the way Americans buy and sell"',
    width: '92px',
    height: '24px',
  },
  {
    src: 'https://images.opendoor.com/source/s3/imgdrop-production/387e28f0c9774fdeabb4ecb65ca70173.png?preset=square-2048&auto=format,compress&ch=Width,DPR&dpr=2&fit=max&w=1024',
    alt: 'Fortune',
    description: '"Fair offer price within minutes"',
    width: '95px',
    height: '22px',
  },
  {
    src: 'https://images.opendoor.com/source/s3/imgdrop-production/8471aa4d2b7746d1917afd262c89545f.png?preset=square-2048&auto=format,compress&ch=Width,DPR&dpr=2&fit=max&w=1024',
    alt: 'The Wall Street Journal',
    description: '"Takes the hassle out of selling"',
    width: '44px',
    height: '24px',
  },
];

export const PressItems: React.NamedExoticComponent<{
  hideTitle?: boolean;
  containerProps?: FlexProps;
  itemProps?: FlexProps;
  pressDetailProps?: {
    primaryImageProps?: ImageProps;
    secondaryImageProps?: ImageProps;
    textProps?: TextProps;
  };
  pressItems?: Array<IPressDetail>;
}> = React.memo(function PressItems({
  hideTitle,
  containerProps,
  itemProps,
  pressDetailProps,
  pressItems,
}) {
  const itemMarginRight = itemProps?.mr ?? '1.5rem';
  const PRESS_ITEMS = pressItems ?? DEFAULT_PRESS_ITEMS;
  return (
    <>
      <Flex
        data-aos="fade-up"
        data-aos-delay="450"
        flexDirection="column"
        justifyContent="center"
        px={['1.5rem', '2rem', '2rem']}
      >
        {!hideTitle && (
          <Text
            mx="auto"
            size="s00"
            my={['1.5rem']}
            lineHeight="s000"
            color="neutrals90"
            fontWeight="semibold"
          >
            OPENDOOR IS FEATURED IN
          </Text>
        )}
      </Flex>
      <Flex
        w="100%"
        mx="auto"
        maxWidth="var(--content-max-width)"
        overflowX="auto"
        data-aos="fade-up"
        overflowY="hidden"
        data-aos-delay="450"
        justifyContent="space-between"
        px={['1.5rem', '2rem', '2rem']}
        className="ads-gallery-thumbnails"
        {...containerProps}
      >
        {PRESS_ITEMS.map((item, index) => (
          <Flex
            pb="1rem"
            key={index}
            flexShrink={0}
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            minWidth={['150px', '200px']}
            {...itemProps}
            // This needs to come after the spread so that it can be overridden
            mr={index !== PRESS_ITEMS.length - 1 ? itemMarginRight : 0}
          >
            <PressDetail pressDetail={item} {...pressDetailProps} />
          </Flex>
        ))}
      </Flex>
    </>
  );
});
