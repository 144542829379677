/* storybook-check-ignore */
import { PropsWithChildren, ReactNode } from 'react';

import { Modal } from '@opendoor/bricks/complex';
import { Box, Button, Flex, Icon, Text } from '@opendoor/bricks/core';
import { IconSize } from '@opendoor/bricks/core/Icon/Icon';

interface Props {
  onRequestClose: () => void;
  isOpen: boolean;
  title: ReactNode;
  modalAnalyticsName: string;
  buttonAnalyticsName: string;
  ['aria-label']: string;
}

function ErrorModal(props: PropsWithChildren<Props>) {
  return (
    <Modal
      analyticsName={props.modalAnalyticsName}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      showClose
      aria-label={props['aria-label']}
    >
      <Flex gridGap="1rem" flexDirection="column">
        <Flex gridGap="1rem" flexDirection="column" marginX="1rem">
          <Box position="absolute" top="2rem">
            <Box borderRadius="rounded" height="40px" width="40px" background="#ffd7cd">
              <Icon name="warning" size={40 as IconSize} color="red50" />
            </Box>
          </Box>
          <Text fontWeight="semibold" fontSize="s1">
            {props.title}
          </Text>
          {props.children}
        </Flex>
        <Button
          analyticsName={props.buttonAnalyticsName}
          aria-label="Close popup modal"
          variant="secondary"
          onClick={props.onRequestClose}
        >
          Got it
        </Button>
      </Flex>
    </Modal>
  );
}

export default ErrorModal;
