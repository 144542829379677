/* storybook-check-ignore */
import React from 'react';

import { Image, ImageProps, Text, TextProps } from '@opendoor/bricks/core';

export interface IPressDetail {
  src: string;
  alt: string;
  description?: string;
  width: string;
  height: string;
  srcSecondary?: string;
  altSecondary?: string;
}

const PressDetail: React.FC<{
  pressDetail: IPressDetail;
  primaryImageProps?: ImageProps;
  secondaryImageProps?: ImageProps;
  textProps?: TextProps;
}> = ({ pressDetail, textProps }) => {
  return (
    <>
      <Image
        src={pressDetail.src}
        alt={pressDetail.alt}
        height={pressDetail.height}
        width={pressDetail.width}
      />
      {pressDetail.srcSecondary && pressDetail.altSecondary && (
        <Image src={pressDetail.srcSecondary} alt={pressDetail.altSecondary} height="24px" />
      )}
      {pressDetail?.description && (
        <Text
          mt="0.5rem"
          color="neutrals90"
          textAlign="center"
          whiteSpace="nowrap"
          fontSize={['s000', 's00', 's0']}
          {...textProps}
        >
          {pressDetail.description}
        </Text>
      )}
    </>
  );
};

export default PressDetail;
