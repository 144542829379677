import { usePromptGoogleOneTap as useSharedPromptGoogleOneTap } from 'components/shared/usePromptGoogleOneTap';

import { setShouldPresentMarketModalOnLS } from '../MarketSelectorModal';

export function usePromptGoogleOneTap(
  source: 'gallery' | 'pdp' | 'login' | 'homepage',
  {
    disablePromptGoogleOneTap,
    delay,
  }: { disablePromptGoogleOneTap?: boolean; delay?: number } = {},
) {
  useSharedPromptGoogleOneTap(
    `cosmos-exclusive-${source}`,
    () => setShouldPresentMarketModalOnLS(true),
    disablePromptGoogleOneTap,
    delay,
  );
}
