/* storybook-check-ignore */
import { useState } from 'react';

import { Flex, Text } from '@opendoor/bricks/core';

import {
  getDefaultMarket,
  MARKET_EMAIL_IDENTIFIERS,
  MarketLocation,
} from '../../../declarations/exclusives/market';
import CampaignCTA, { Props as CampaignCTAProps } from '../CampaignCTA';
import MarketDropdown from '../MarketDropdown';

type Props = {
  buttonInitialText?: string;
  analyticsSource: string;
  onAfterSubmit?: (market: MarketLocation) => void;
} & Omit<Partial<CampaignCTAProps>, 'onAfterSubmit'>;

function ContactCTA({
  buttonInitialText = 'Send me new homes',
  analyticsSource,
  onAfterSubmit,
  ...ctaProps
}: Props) {
  const [market, setMarket] = useState<MarketLocation>(getDefaultMarket);
  const campaignMarket = MARKET_EMAIL_IDENTIFIERS[market];

  return (
    <Flex flexDirection="column" className="footer-cta-input" minHeight="166px" gridGap="0.625rem">
      <Text fontSize="s1" lineHeight="s2" fontWeight="semibold" mb="0.625rem" as="div">
        Send me new homes in <MarketDropdown market={market} setMarket={setMarket} />
      </Text>
      <CampaignCTA
        buttonInitialText={buttonInitialText}
        campaignName={'exclusive-listings'}
        campaignAdditionalArgs={[campaignMarket]}
        campaignMarket={campaignMarket}
        errorModalAnalyticsPrefix="contact-cta"
        analyticsSource={analyticsSource}
        onAfterSubmit={onAfterSubmit && (() => onAfterSubmit(market))}
        {...ctaProps}
      />
    </Flex>
  );
}

export default ContactCTA;
