/* storybook-check-ignore */
import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, BoxProps, Icon, Text } from '@opendoor/bricks/core';
import { media } from '@opendoor/bricks/theme/legacy/foundations/mediaBreakpoints';

import {
  MARKETS as CURRENT_MARKETS,
  MARKET_TEXT,
  MarketLocation,
  STATE,
} from '../../declarations/exclusives/market';

const MARKETS = CURRENT_MARKETS.map((market) => ({
  label: `${MARKET_TEXT[market]}, ${STATE[market]}`,
  value: market,
}));

const PrimaryNavSubmenu = styled.ul<{ isExpanded: boolean }>`
  z-index: 2;
  padding: 1.5rem;
  min-width: 140px;
  list-style: none;
  text-align: right;
  position: absolute;
  border-radius: 0 0 10px 10px;
  transition: opacity ease 0.3s;
  -webkit-transition: opacity ease 0.3s;
  font-weight: var(--fontWeights-semibold);
  background-color: var(--colors-neutrals0);
  box-shadow: 0px 10px 22px rgba(29, 71, 123, 0.08), 0px 2px 12px rgba(29, 71, 123, 0.08);
  margin-top: 0px;

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    `}
`;

const PrimaryNavItem = styled.li`
  height: 30px;
  line-height: 1em;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const MenuItem = styled.span`
  border-radius: 5px;
  position: relative;
  cursor: pointer;
`;

function MarketMenu({
  markets,
  isExpanded,
  closeMenu,
  onMarketClick,
  triggerRef,
  style,
}: {
  markets: typeof MARKETS;
  isExpanded: boolean;
  closeMenu: () => void;
  onMarketClick: (market: MarketLocation) => void;
  triggerRef: MutableRefObject<HTMLButtonElement | null>;
  style?: CSSProperties;
}) {
  const ref = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node | null;
      if (
        ref.current &&
        !ref.current.contains(target) &&
        triggerRef.current &&
        !triggerRef.current.contains(target)
      ) {
        closeMenu();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [closeMenu, isExpanded, triggerRef]);

  return (
    <PrimaryNavSubmenu
      ref={ref}
      style={style}
      isExpanded={isExpanded}
      // a11y
      aria-hidden={isExpanded ? 'false' : 'true'}
    >
      {markets.map(({ label, value }) => (
        <PrimaryNavItem key={value}>
          <MenuItem
            onClick={() => {
              onMarketClick(value as MarketLocation);
              closeMenu();
            }}
          >
            <Text fontSize="s1" fontWeight="medium">
              {label}
            </Text>
          </MenuItem>
        </PrimaryNavItem>
      ))}
    </PrimaryNavSubmenu>
  );
}

const MarketDropdown: React.FC<
  {
    market: MarketLocation;
    setMarket: (update: MarketLocation) => void;
    dropdownStyle?: CSSProperties;
  } & BoxProps
> = ({ market, setMarket, dropdownStyle, ...props }) => {
  const [marketMenuIsOpen, setMarketMenuIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const closeMenu = () => setMarketMenuIsOpen(false);
  const toggleMenu = () => setMarketMenuIsOpen((prevState) => !prevState);
  return (
    <Box
      display="inline-block"
      css={css`
        svg {
          color: inherit;
          transition: transform 0.25s ease-in-out;
          transform: rotate(0deg);
        }
        &.expanded {
          svg {
            transform: rotate(180deg);
          }
        }
      `}
      className={marketMenuIsOpen ? 'expanded' : undefined}
    >
      <Box
        ref={(ref: any) => (triggerRef.current = ref)}
        as="button"
        paddingX="0"
        cursor="pointer"
        color="brand50"
        border="none"
        fontWeight="medium"
        fontSize={['s0', 's1', 's1', 's1']}
        lineHeight={['s0', 's1', 's1', 's1']}
        backgroundColor="transparent"
        onClick={toggleMenu}
        css={css`
          ${media.smallerThan.MD} {
            svg {
              width: 0.75rem;
              height: 0.75rem;
            }
          }
        `}
        {...props}
      >
        {MARKET_TEXT[market]}, {STATE[market]}
        <Icon flexShrink={0} ml={3} name="chevron-down" size={16} />
      </Box>
      <MarketMenu
        triggerRef={triggerRef}
        markets={MARKETS}
        onMarketClick={setMarket}
        closeMenu={closeMenu}
        isExpanded={marketMenuIsOpen}
        style={dropdownStyle}
      />
    </Box>
  );
};

export default MarketDropdown;
