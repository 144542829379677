/* storybook-check-ignore */
import { useEffect, useMemo, useRef, useState } from 'react';

import { CtaInput } from '@opendoor/bricks/complex';
import { IProps } from '@opendoor/bricks/complex/CtaInput/shared';
import { Box, Button, Input, Text } from '@opendoor/bricks/core';
import { checkEmailValidity } from '@opendoor/bricks/core/Input/helpers';
import { Suggestion } from '@opendoor/bricks/core/Input/Input';
import { useDevice } from '@opendoor/bricks/hooks/useMedia';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

import { MARKET_EMAIL_IDENTIFIERS_TO_MARKET_ID } from 'declarations/exclusives/market';

import ErrorModal from './ErrorModal';
import useCampaignSubscription from './hooks/useCampaignSubscription';

function getErrorModalTitle(errorStatusCode: number | null) {
  switch (errorStatusCode) {
    case 422:
      return 'There was an error with your email address.';
    default:
      return 'There was an error with your email on our end.';
  }
}

function getErrorModalContent(errorStatusCode: number | null) {
  switch (errorStatusCode) {
    case 422:
      return 'Please provide a properly formatted email address.';
    default:
      return 'Sorry! Please try again later.';
  }
}

export interface Props {
  initialEmailAddress?: string;
  campaignName: string;
  campaignAdditionalArgs?: string[];
  campaignMarket: string;
  buttonInitialText?: string;
  buttonAfterSubscription?: string;
  errorModalAnalyticsPrefix: string;
  ctaInputProps?: Partial<Omit<IProps, 'ctaProps'>> & { ctaProps?: Partial<IProps['ctaProps']> };
  analyticsSource: string;
  onAfterSubmit?: () => void;
}

function CampaignCTA({
  initialEmailAddress = '',
  campaignName,
  campaignAdditionalArgs = [],
  campaignMarket,
  errorModalAnalyticsPrefix,
  buttonInitialText = 'Send me new homes',
  buttonAfterSubscription = 'Done!',
  ctaInputProps,
  analyticsSource,
  onAfterSubmit = () => {},
}: Props) {
  const { isMobile } = useDevice();
  const [emailAddress, setEmailAddress] = useState(initialEmailAddress);
  const [suggestion, _setSuggestion] = useState<string | undefined>(undefined);
  const analyticsLabel = buttonInitialText.toLowerCase().replaceAll(' ', '-');
  const campaign = useCampaignSubscription(campaignName, emailAddress, analyticsLabel, {
    args: campaignAdditionalArgs,
    analyticsAdditionalContextualData: {
      market: MARKET_EMAIL_IDENTIFIERS_TO_MARKET_ID[campaignMarket],
      source: analyticsSource,
    },
    onSuccess: onAfterSubmit,
  });
  const setSuggestionRef = useRef(
    debounce(async (value: string | undefined) => {
      if (value) {
        const { suggestion } = await checkEmailValidity(value);
        _setSuggestion(suggestion);
      }
    }, 1000),
  );

  const onEmailSubmit = async () => {
    if (!emailAddress) {
      return;
    } else if (campaign.isSubscribed) {
      onAfterSubmit();
      return;
    }

    await campaign.subscribe({ market: campaignMarket });
  };

  useEffect(() => {
    const setSuggestion = setSuggestionRef.current;
    return () => setSuggestion.cancel();
  }, []);

  const buttonText = useMemo(
    () => (campaign.isSubscribed ? buttonAfterSubscription : buttonInitialText),
    [campaign.isSubscribed, buttonInitialText, buttonAfterSubscription],
  );

  return (
    <>
      {!isMobile ? (
        <Box>
          <CtaInput
            designStyle="roundedSquare"
            placeholderText="Email address"
            value={emailAddress}
            paddingLevel="medium"
            showBorder={false}
            inputType="text"
            autoFocus={false}
            autoComplete={'email'}
            onChange={(event) => {
              setEmailAddress(event?.target.value || '');
              setSuggestionRef.current(event?.target.value);
            }}
            onSubmit={async (e) => {
              e.preventDefault();
              onEmailSubmit();
            }}
            ariaLabel={'Submit email address to get exclusive listings'}
            unboundedWidth={true}
            analyticsName="cosmos-exclusives-lisitings-campaign"
            {...ctaInputProps}
            ctaProps={{
              size: 'lg',
              'aria-label': 'Submit email address to get exclusive listings',
              actionText: buttonText,
              actionTextSmall: buttonText,
              loading: campaign.isLoading,
              disabled: campaign.isLoading,
              /** TODO @exclusives Semantic buttons without onClick methods are a11y violations. See https://opendoor.atlassian.net/wiki/spaces/SELL/pages/1943339236/Accessibility+best+practices+for+frontend+development#Adding-onClick-handlers-to-non-interactive-elements-such-as-%3Cdiv-/%3E-or-%3CBox-/%3E-not-started */
              onClick: noop,
              ...ctaInputProps?.ctaProps,
            }}
          />
        </Box>
      ) : (
        <>
          <Input
            id="cosmos-exclusives-campaign-cta-input"
            type="email"
            placeholder="Email address"
            analyticsName={`cosmos-exclusives-${analyticsLabel}-input`}
            onChange={(_, val) => setEmailAddress(val || '')}
            value={emailAddress}
            aria-labelledby=""
            minHeight="3rem"
          />
          <Button
            analyticsName={`cosmos-exclusives-${analyticsLabel}-button`}
            aria-label={null}
            onClick={(ev) => {
              ev.preventDefault();
              onEmailSubmit();
            }}
            disabled={campaign.isLoading}
            loading={campaign.isLoading}
            minHeight="3rem"
          >
            {buttonText}
          </Button>
        </>
      )}
      {suggestion && !isMobile && (
        <Suggestion
          fontSize="s1"
          suggestion={suggestion}
          useSuggestion={() => {
            if (!suggestion) return;
            setEmailAddress(suggestion);
            _setSuggestion(undefined);
          }}
        />
      )}
      <ErrorModal
        isOpen={!!campaign.errorStatusCode}
        onRequestClose={campaign.cleanError}
        title={getErrorModalTitle(campaign.errorStatusCode)}
        modalAnalyticsName={`cosmos-exclusives-${errorModalAnalyticsPrefix}-error-modal`}
        buttonAnalyticsName={`cosmos-exclusives-${errorModalAnalyticsPrefix}-error-modal-confirmation`}
        aria-label="Campaign subscription error"
      >
        <Text fontSize="s1">{getErrorModalContent(campaign.errorStatusCode)}</Text>
      </ErrorModal>
    </>
  );
}

export default CampaignCTA;
