/* storybook-check-ignore */
const ChevronLeft = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82377 3.82374C9.18473 3.1847 8.14864 3.1847 7.5096 3.82374L4.16692 7.16643C3.52788 7.80547 3.52788 8.84155 4.16692 9.48059L7.5096 12.8233C8.14864 13.4623 9.18473 13.4623 9.82377 12.8233C10.4628 12.1842 10.4628 11.1482 9.82377 10.5091L7.63817 8.32351L9.82377 6.13791C10.4628 5.49887 10.4628 4.46278 9.82377 3.82374Z"
      fill="#222222"
    />
  </svg>
);

export default ChevronLeft;
