/* storybook-check-ignore */
const ChevronRight = ({ color = '#222222' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17617 12.1762C6.81521 12.8153 7.8513 12.8153 8.49034 12.1762L11.833 8.83356C12.4721 8.19452 12.4721 7.15843 11.833 6.51939L8.49034 3.1767C7.8513 2.53766 6.81521 2.53766 6.17617 3.1767C5.53713 3.81574 5.53713 4.85183 6.17617 5.49087L8.36177 7.67647L6.17617 9.86208C5.53713 10.5011 5.53713 11.5372 6.17617 12.1762Z"
      fill={color}
    />
  </svg>
);

export default ChevronRight;
