/* storybook-check-ignore */
import React, { memo, useEffect } from 'react';

import { Box, Button, Flex, Link, Text } from '@opendoor/bricks/core';
import { analytics, globalObservability } from '@opendoor/observability/slim';
import Aos from 'aos';
import filter from 'lodash/filter';
import { GetServerSidePropsResult } from 'next';
import { useRouter } from 'next/router';

import { EXCLUSIVE_LISTING_SEARCH_ENDPOINT } from 'components/exclusives/constants/endpoints';
import ErrorOnListingsFetchBanner from 'components/exclusives/ErrorOnListingsFetchBanner';
import { listingsFetcher } from 'components/exclusives/fetcher/listingsFetcher';
import useExclusiveAnalytics from 'components/exclusives/hooks/useExclusiveAnalytics';
import { usePromptGoogleOneTap } from 'components/exclusives/hooks/usePromptGoogleOneTap';
import { BuyerLandingPageV2 } from 'components/exclusives/landing/BuyerLandingPageV2';
import { LandingLayout } from 'components/exclusives/layouts/LandingLayout';
import { PressItems } from 'components/exclusives/Press';
import { ResponsivePicture } from 'components/exclusives/ResponsivePicture';

import { ExclusivesTypeContextProvider } from 'helpers/exclusives/ExclusivesTypeContext';
import { getListingWithComputedProperties } from 'helpers/exclusives/listing';

import Accordion from '../../components/exclusives/Accordion';
import ContactDetails from '../../components/exclusives/contact/ContactDetails';
import CustomerTestimonialSection from '../../components/exclusives/CustomerTestimonialSection';
import GallerySlider from '../../components/exclusives/gallery/GallerySlider';
import HomeHero from '../../components/exclusives/HomeHero';
import ArrowRightEero from '../../components/exclusives/icons/ArrowRightEero';
import Footer from '../../components/exclusives/layout/Footer';
import DefaultLayout from '../../components/exclusives/layouts/DefaultLayout';
import Seo from '../../components/exclusives/Seo';
import ReduxWrapper from '../../components/shared/ReduxWrapper';
import { ListingWithComputedProperties } from '../../declarations/exclusives/listing';
import { getFaqItems } from '../../helpers/exclusives/faq';

const Sentry = globalObservability.getSentryClient();

const generalFaqItems = getFaqItems([
  'what-are-opendoor-exclusives',
  'how-does-the-process-work',
  'how-does-pricing-work-can-i-negotiate-the-price',
]).map(({ title, ...values }) => ({
  ...values,
  title: (
    <Text fontWeight="semibold" fontSize="s1" lineHeight="s1" as="h3" mb="0">
      {title}
    </Text>
  ),
}));

interface Props {
  homes: ListingWithComputedProperties[];
  errorOnListingsFetch: boolean;
}

export async function getServerSideProps(): Promise<GetServerSidePropsResult<Props>> {
  let errorOnListingsFetch = false;
  const body: ListingWithComputedProperties[] = (
    await listingsFetcher({
      endpoint: EXCLUSIVE_LISTING_SEARCH_ENDPOINT,
      includeOomls: false,
    }).catch((err) => {
      Sentry.captureException?.(err);
      errorOnListingsFetch = true;
      return [];
    })
  ).map(getListingWithComputedProperties);
  return {
    props: {
      homes: body,
      errorOnListingsFetch,
    },
  };
}

const NewHomes = memo(function NewHomes() {
  return (
    <Box boxSizing="content-box">
      <Flex
        data-aos="fade-up"
        data-aos-offset="700"
        data-aos-duration="1000"
        overflow={['hidden', 'hidden', 'hidden', 'visible']}
        marginX="auto"
        px={['0', '0', '0', '2rem']}
        maxH={['316px', '380px', '428px', 'max(538px, 65vh)']}
        maxWidth={['100%', '100%', '100%', 'var(--content-max-width)']}
      >
        <ResponsivePicture
          transform="auto"
          position="relative"
          translateY={['0', '0', '0', '10rem']}
          objectFit="cover"
          src="https://images.opendoor.com/source/s3/imgdrop-production/285beba0a7934c1d9d328287ad70ed07.png?preset=square-2048"
          alt="Couple in front of a house"
          height={['316px', '380px', '428px', 'auto']}
          width={['auto', 'auto', 'auto', '100%']}
          borderRadius={['none', 'none', 'none', 'semiRounded']}
        />
      </Flex>
      <Box bg="brand50">
        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          minH="410px"
          pt={['auto', 'auto', 'auto', '7.25rem']}
          m="auto"
          maxWidth="var(--header-max-width)"
          px={['1.5rem', '2rem']}
          alignItems="center"
          justifyContent="space-between"
          textAlign={['center', 'center', 'center', 'left']}
        >
          <Box maxW="320px" mt={['4rem', '4rem', '4rem', '0']}>
            <Text
              color="neutrals0"
              fontStyle="italic"
              fontWeight="normal"
              fontFamily="serif"
              size="s6"
              // @ts-expect-error letter-spacing is now tokenized in bricks
              letterSpacing="-0.05em"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              300+
            </Text>
            <Text
              color="neutrals0"
              mt="1.5rem"
              fontSize="s2"
              lineHeight="s2"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              Monthly new homes dropped in Austin, Houston, and Dallas-Fort Worth
            </Text>
          </Box>
          <Box my="4rem" maxW="320px">
            <Text
              color="neutrals0"
              fontFamily="serif"
              size="s6"
              fontStyle="italic"
              fontWeight="normal"
              // @ts-expect-error letter-spacing is now tokenized in bricks
              letterSpacing="-0.05em"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              $100m+
            </Text>
            <Text
              color="neutrals0"
              mt="1.5rem"
              fontSize="s2"
              lineHeight="s2"
              data-aos="fade-up"
              data-aos-delay="350"
            >
              Homes sold via Exclusives
            </Text>
          </Box>
          <Box maxW="320px" mb={['4rem', '4rem', '4rem', '0']}>
            <Text
              color="neutrals0"
              fontFamily="serif"
              size="s6"
              fontStyle="italic"
              fontWeight="normal"
              // @ts-expect-error letter-spacing is now tokenized in bricks
              letterSpacing="-0.05em"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              $1m+
            </Text>
            <Text
              color="neutrals0"
              mt="1.5rem"
              fontSize="s2"
              lineHeight="s2"
              data-aos="fade-up"
              data-aos-delay="450"
            >
              Matched via Appraisal Price Match Guarantee
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
});

const SliderGallery = memo(function SliderGallery({
  ctaTarget,
  homes,
  errorOnListingsFetch,
}: {
  ctaTarget: 'onboarding' | 'gallery';
  homes: ListingWithComputedProperties[];
  errorOnListingsFetch: boolean;
}) {
  const listedListings = filter(homes, (o) => o.exclusive_listing_info.state === 'el_listed');

  return (
    <Box bg="neutrals20">
      <Box data-aos="fade-up">
        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="space-around"
          alignItems="center"
          mx={['1.5rem', '1.5rem', null]}
        >
          <Text
            fontSize="s3"
            lineHeight="s3"
            fontWeight="bold"
            textAlign={['left', 'left', 'center']}
            pb={['1rem', '1rem', '1rem', '5rem']}
            pt={['2rem', '2rem', '3rem', '5rem']}
            mr={['0rem', '0rem', '0rem', '4rem']}
            as="h2"
          >
            {homes.length || ''} exclusive
            {homes.length > 1 ? 's' : ''} homes in{' '}
            <Text as="span" fontFamily="serif" fontStyle="italic">
              Austin, Houston, and Dallas-Fort Worth
            </Text>
          </Text>
          <Link
            fontSize="s2"
            fontWeight="semibold"
            href={`/exclusives/${ctaTarget}`}
            analyticsName="cosmos-exclusives-see-all-exclusive-homes"
            aria-label="See all exclusives"
          >
            See all
            <Box as="span" ml="0.25rem" mt="0.25rem">
              <ArrowRightEero />
            </Box>
          </Link>
        </Flex>
        {!errorOnListingsFetch ? (
          <GallerySlider
            listings={listedListings}
            pb={['2rem', '4rem', '6rem']}
            pt={['2rem', '2rem', '2rem', '0']}
          />
        ) : (
          <Flex maxWidth="max(900px, 60vw)" mx="auto" py="2rem" px={['1.5rem', '2rem']}>
            <ErrorOnListingsFetchBanner bgColor="neutrals10" />
          </Flex>
        )}
      </Box>
    </Box>
  );
});

const Info = memo(function Info() {
  return (
    <Flex
      flexWrap="wrap"
      flexDirection="column"
      maxWidth="max(900px, 60vw)"
      mx="auto"
      py="2rem"
      px={['1.5rem', '2rem']}
    >
      <Flex flex="1" flexWrap="wrap" justifyContent="space-between" mt="3rem">
        <Box data-aos="fade-up" maxW="375px" mt="3rem" as={'h2'}>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold" color="brand50">
            Exclusive prices
          </Text>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold" mb="1rem">
            for up to 14 days
          </Text>
          <Text fontSize="s1" lineHeight="s1">
            Beat the market. Our off-market Exclusive price is less than what they will list at on
            the public market.
          </Text>
        </Box>
        <Box data-aos="fade-up" data-aos-delay="300" maxW="375px" mt="3rem" as={'h2'}>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold">
            Appraisal Price
          </Text>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold" mb="1rem">
            Match{' '}
            <Text as="span" fontWeight="semibold" color="brand50">
              Guarantee
            </Text>
          </Text>
          <Text fontSize="s1" lineHeight="s1">
            Simply pay the off-market exclusive price for the home. If it is appraised for less,
            we’ll match it.
            <Link
              href="/exclusives/faq?apmg=1"
              aria-label="Go to frequently asked questions for Appraisal Price Match Guarantee"
              target="_blank"
              rel="noopener noreferrer"
              fontWeight="semibold"
              analyticsName="cosmos-exclusive-slider-gallery-appraisal-faqs"
            >
              {' '}
              Learn more.
            </Link>
          </Text>
        </Box>
      </Flex>
      <Flex flex="1" flexWrap="wrap" justifyContent="space-between">
        <Box data-aos="fade-up" data-aos-delay="400" maxW="375px" mt="3rem" as={'h2'}>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold">
            Back out anytime
          </Text>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold" mb="1rem" color="brand50">
            Get your money back
          </Text>
          <Text fontSize="s1" lineHeight="s1">
            You can back out anytime before closing, and still get 100% of your earnest money
            deposit back.
          </Text>
        </Box>
        <Box data-aos="fade-up" data-aos-delay="200" maxW="375px" mt="3rem" as={'h2'}>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold">
            Say{' '}
            <Text as="span" color="brand50" fontWeight="semibold">
              goodbye
            </Text>{' '}
            to
          </Text>
          <Text fontSize="s4" lineHeight="s4" fontWeight="semibold" mb="1rem">
            bidding wars
          </Text>
          <Text fontSize="s1" lineHeight="s1">
            Never be outbid again. Find a home you love, sign the contract, and it’s yours. It’s
            that simple.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
});

const FAQ = memo(function FAQ() {
  return (
    <Flex
      maxWidth="var(--content-max-width)"
      mx="auto"
      px={['1.5rem', '2rem']}
      flexDirection={['column', 'column', 'row', 'row']}
      alignItems="top"
      justifyContent="space-between"
      my={['2rem', '2rem', '2rem', '6rem']}
      mt="3rem"
    >
      <Text
        fontSize="s5"
        lineHeight="s5"
        color="neutrals100"
        fontWeight="bold"
        pb="2rem"
        mt={['1rem', '1rem', '1rem', '1.5rem']}
        mr={['unset', 'unset', '2rem']}
        textAlign="left"
        as={'h2'}
        data-aos="fade-up"
      >
        Questions?
        <br />
        <Text as="span" fontStyle="italic" fontWeight="normal" color="brand50" fontFamily="serif">
          We have answers.
        </Text>
      </Text>

      <Box maxW={['unset', 'unset', '400px', '580px']} width="100%" data-aos="fade-up">
        <Accordion bottomBorder items={generalFaqItems} accordionName="indexFaqAccordion" />
        <Button
          aria-label="See all frequently asked questions"
          size="lg"
          minW="210px"
          height="56px"
          maxW="210px"
          mx="0px"
          mt={['2rem', '3rem', '3rem', '4.5rem']}
          color="neutrals100"
          border="2px solid neutrals100"
          bg="neutrals0"
          _hover={{ backgroundColor: 'neutrals100', color: 'neutrals0' }}
          onClick={() => {
            window.location.assign('/exclusives/faq');
          }}
          analyticsName="cosmos-exclusive-slider-gallery-see-all-faqs"
        >
          See all FAQs
        </Button>
      </Box>
    </Flex>
  );
});

const TITLE = "Opendoor Exclusives | Self-tour and buy homes before they're listed anywhere else";
const LEGACY_TITLE = `Opendoor Exclusives | Off-market homes you can't find anywhere else`;

export function LegacyPageContent({ homes, errorOnListingsFetch }: Props) {
  usePromptGoogleOneTap('homepage');
  const router = useRouter();
  const { trackExclusivesEvent } = useExclusiveAnalytics();

  useEffect(() => {
    Aos.init({ once: true });
    if (router.query.email) {
      analytics.register({ email: router.query.email });
    }
    if (router.query.utm_source) {
      trackExclusivesEvent('exclusives-home-page', 'view', undefined, {
        utm_source: router.query.utm_source,
        utm_campaign: router.query.utm_campaign,
      });
    }
  }, [router.query.email, router.query.utm_source, router.query.utm_campaign]);

  const ctaTarget = router.query.ads ? 'onboarding' : 'gallery';

  return (
    <React.Fragment>
      <Seo title={LEGACY_TITLE}>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
                .footer-cta-input .cta-input-button button {
                  background-color: brand50;
                }
              `,
          }}
        />
      </Seo>
      <DefaultLayout
        headerProps={{
          isSearchHidden: true,
          maxWidth: 'var(--content-max-width)',
        }}
        title={LEGACY_TITLE}
        hideHeaderNavLinks={false}
      >
        <Box as="main">
          <HomeHero />
          <PressItems />
          <Info />
          <CustomerTestimonialSection />
          <NewHomes />
          <SliderGallery
            ctaTarget={ctaTarget}
            homes={homes}
            errorOnListingsFetch={errorOnListingsFetch}
          />
          <FAQ />
          <ContactDetails analyticsSource="home" />
          <Footer />
        </Box>
      </DefaultLayout>
    </React.Fragment>
  );
}

const PageContent = (props: Props) => {
  usePromptGoogleOneTap('homepage');
  const router = useRouter();
  const { trackExclusivesEvent } = useExclusiveAnalytics();

  useEffect(() => {
    Aos.init({ once: true });
    if (router.query.email) {
      analytics.register({ email: router.query.email });
    }
    if (router.query.utm_source) {
      trackExclusivesEvent('exclusives-home-page', 'view', undefined, {
        utm_source: router.query.utm_source,
        utm_campaign: router.query.utm_campaign,
      });
    }
  }, [router.query.email, router.query.utm_source, router.query.utm_campaign]);

  return (
    <>
      <Seo title={TITLE}>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
                .footer-cta-input .cta-input-button button {
                  background-color: brand50;
                }
              `,
          }}
        />
      </Seo>
      <ExclusivesTypeContextProvider type="exclusives">
        <LandingLayout>
          <BuyerLandingPageV2 {...props} />
        </LandingLayout>
      </ExclusivesTypeContextProvider>
    </>
  );
};

function Page(props: Props) {
  return (
    <ReduxWrapper>
      <PageContent {...props} />
    </ReduxWrapper>
  );
}
export default Page;
