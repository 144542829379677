/* storybook-check-ignore */
import Slider, { Settings as SliderSettings } from 'react-slick';

import { css } from '@emotion/react';
import { Box, Button, Flex, FlexProps, Link, Text } from '@opendoor/bricks/core';

import {
  TESTIMONIAL_STATUS_DATE_INFO,
  TestimonialProps,
  TESTIMONIALS,
} from '../exclusives/constants/testimonials';
import ArrowRightEero from './icons/ArrowRightEero';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';

const boxCss = css`
  transition: box-shadow 0.25s ease-in-out;
  img {
    transition: all 0.5s;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
`;

export const CustomerTestimonial: React.FC<TestimonialProps & { containerProps?: FlexProps }> = ({
  snippet,
  customer,
  status,
  location,
  id,
  containerProps,
}) => (
  <Link
    href={'/exclusives/testimonials#' + id}
    aria-label="Read the full review"
    analyticsName={`exclusives-full-testimonial-${id}`}
    cursor="pointer"
    height="100%"
    display="block"
    maxW="500px"
  >
    <Flex
      css={boxCss}
      bg="neutrals0"
      borderRadius="semiRounded"
      boxShadow="0px 5px 10px rgba(29, 71, 123, 0.1)"
      _hover={{ boxShadow: '0px 5px 12px rgba(29, 71, 123, 0.2)' }}
      flexDirection="column"
      mx="1rem"
      height="100%"
      minH="300px"
      p="2rem"
      {...containerProps}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <svg
          width="21"
          height="15"
          viewBox="0 0 21 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.9918 15C7.34426 15 9.35246 13.5119 9.35246 11.0714C9.35246 8.63095 7.86065 7.14286 5.90984 7.14286C5.16393 7.14286 4.53279 7.44047 4.2459 7.67857C4.18852 5.29762 5.7377 3.03571 9.40984 1.30953L9.0082 0C3.09836 1.30952 0 4.70238 0 9.52381C0 13.1548 2.46721 15 4.9918 15ZM16.582 15C18.9344 15 20.9426 13.5119 20.9426 11.0714C20.9426 8.63095 19.4508 7.14286 17.5 7.14286C16.7541 7.14286 16.1229 7.44047 15.8361 7.67857C15.7787 5.29762 17.3279 3.03571 21 1.30953L20.5984 0C14.6885 1.30952 11.5902 4.70238 11.5902 9.52381C11.5902 13.1548 14.0574 15 16.582 15Z"
            fill="#222222"
          />
        </svg>
        <Text color="neutrals90">{location}</Text>
      </Flex>
      <Text marginTop="1.5rem" fontSize="s1" lineHeight="s0">
        {snippet}
      </Text>
      <Flex flexDirection="row" marginTop="auto">
        <Box marginTop="1rem">
          <Text marginBottom="0" fontWeight="semibold" fontSize="s1" lineHeight="s0">
            {customer.name}
          </Text>
          <Text marginBottom="1rem" fontSize="s0" color="neutrals90" lineHeight="s0">
            {TESTIMONIAL_STATUS_DATE_INFO[status]} {customer.dateInfo}
          </Text>
          <Text fontWeight="semibold" fontSize="s0" lineHeight="s00" color="brand50">
            Read the full review
          </Text>
        </Box>
      </Flex>
    </Flex>
  </Link>
);

const arrowCss = css`
  width: 0;
  height: 0;
  z-index: 5;
  top: 40%;
  position: absolute;
  ::before {
    display: none;
  }
`;

// position the arrow to the left of its parent element so that both buttons are equally spaced from screen edges
const nextButtonCss = css`
  transform: translateX(-100%);
`;

const prevArrow = (
  <Box css={arrowCss} left={['1.5rem', '1.5rem', '2rem', '3rem']}>
    <Button
      type="button"
      variant="icon"
      aria-label="Previous slide"
      onClick={() => {}}
      analyticsName="cosmos-exclusive-slider-testimonials-left"
    >
      <ChevronLeft />
    </Button>
  </Box>
);
const nextArrow = (
  <Box css={arrowCss} right={['1.5rem', '1.5rem', '2rem', '3rem']}>
    <Button
      css={nextButtonCss}
      type="button"
      variant="icon"
      onClick={() => {}}
      aria-label="Next slide"
      analyticsName="cosmos-exclusive-slider-testimonials-right"
    >
      <ChevronRight />
    </Button>
  </Box>
);

const DEFAULT_SLIDER_SETTINGS: SliderSettings = {
  dots: false,
  infinite: true,
  speed: 200,
  className: 'center',
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 3,
  autoplay: false,
  draggable: true,
  arrows: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        speed: 300,
        autoplay: false,
        draggable: true,
        slidesToShow: 2,
        className: 'center',
        centerMode: true,
        centerPadding: '60px',
      },
    },
    {
      breakpoint: 720,
      settings: {
        speed: 300,
        autoplay: false,
        draggable: true,
        slidesToShow: 1,
        className: 'center',
        centerMode: true,
        centerPadding: '60px',
      },
    },
  ],
};

// ensure all cards are the same height on any viewport width
const sliderCss = css`
  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: inherit;
    padding-bottom: 2rem;
  }

  .slick-slide > div {
    height: 100%;
  }
`;

function CustomerTestimonialSection() {
  return (
    <Box
      css={sliderCss}
      data-aos="fade-up"
      data-aos-offset="500"
      data-aos-duration="1000"
      maxWidth="100%"
      mt={['3.5rem', '5rem', '6.25rem']}
      data-testid="testimonials"
    >
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="space-around"
        alignItems="center"
        mx={['1.5rem', '1.5rem', null]}
        mb="2rem"
      >
        <Text
          fontSize="s3"
          lineHeight="s3"
          fontWeight="bold"
          textAlign={['left', 'left', 'center']}
          pb={['1rem', '1rem', '1rem', '3rem']}
          pt={['0', '0', '1rem', '3rem']}
          mr={['0rem', '0rem', '0rem', '4rem']}
          as="h2"
        >
          See what{' '}
          <span style={{ fontFamily: 'Publico', fontStyle: 'italic', fontWeight: 'normal' }}>
            our customers
          </span>{' '}
          have to say
        </Text>{' '}
        <Link
          fontSize="s2"
          fontWeight="semibold"
          href="/exclusives/testimonials"
          analyticsName="cosmos-exclusives-see-all-testimonials"
          aria-label="See all testimonials"
        >
          See all
          <Box as="span" ml="0.25rem" mt="0.25rem">
            <ArrowRightEero />
          </Box>
        </Link>
      </Flex>
      <Slider nextArrow={nextArrow} prevArrow={prevArrow} {...DEFAULT_SLIDER_SETTINGS}>
        {TESTIMONIALS.map((props) => (
          <CustomerTestimonial key={props.id} {...props} />
        ))}
      </Slider>
    </Box>
  );
}

export default CustomerTestimonialSection;
