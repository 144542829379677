/* storybook-check-ignore */
import React from 'react';

import { Box, Flex, Image, Link, Text } from '@opendoor/bricks/core';

import ContactCTA from './ContactCTA';

// const detailsItems = [
//   {
//     value: 'Skip the bidding wars',
//   },
//   {
//     value: 'See 100s of off-market homes',
//   },
//   {
//     value: 'Self-tour and buy on your time',
//   },
//   {
//     value: 'Back out anytime',
//   },
//   {
//     value: 'Expert guidance 7 days a week',
//   },
// ];

const ContactDetails: React.FC<{ analyticsSource: string }> = ({ analyticsSource }) => {
  return (
    <Box w="100%" bg="neutrals20">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={['4rem', '4rem', '6rem']}
        flexDirection={['column-reverse', 'column-reverse', 'row']}
        maxWidth="var(--header-max-width)"
        mx="auto"
        px={['1.5rem', '2rem']}
        data-aos="fade-up"
      >
        <Box pb="1rem" mr={['unset', 'unset', '2rem']}>
          <Flex borderBottomRadius="semiRounded" flexDirection="row">
            <Flex flexDirection="column" pb="3rem">
              <Box pb="1.5rem">
                <Image
                  src="https://images.opendoor.com/source/s3/imgdrop-production/f62db0d0dabf4680bea8a9359da50655.png?preset=square-2048&auto=compress,format&w=110"
                  loading="lazy"
                  borderRadius="rounded"
                  alt="Vinay Ramesh"
                  w="6.5rem"
                />
              </Box>
              <Text fontSize="s2" lineHeight="s2">
                <Text as="span" fontWeight="semibold">
                  Vinay Ramesh | Product Specialist
                </Text>
                <br />
                exclusive@opendoor.com
                <br />
                (512) 706-9938
              </Text>
            </Flex>
          </Flex>
          <Link
            aria-label=""
            fontSize="s1"
            lineHeight="s1"
            display="inline-block"
            fontWeight="semibold"
            onClick={() => window.FrontChat('show')}
            analyticsName="cosmos-exclusives-contact-details-chat"
          >
            Questions? Let’s chat
          </Link>
        </Box>
        <Box pb="3rem" maxW={['unset', 'unset', '400px', '580px']} width="100%">
          <Flex flexDirection="column">
            <Text
              fontSize="s5"
              fontWeight="bold"
              lineHeight="s5"
              maxW="490px"
              mb="2rem"
              mt="2rem"
              // @ts-expect-error letter-spacing is now tokenized in bricks
              letterSpacing="-0.02em"
              as="h2"
            >
              See new off-market homes{' '}
              <Text
                as="span"
                fontStyle="italic"
                color="brand50"
                fontWeight="normal"
                fontFamily="serif"
              >
                only available on Opendoor Exclusives
              </Text>
            </Text>
            <Text fontSize="s1" maxWidth="30rem" pb="2rem">
              Don’t miss out on your dream home. Sign up to get exclusive, off-market homes
              delivered to your inbox.
            </Text>
            <ContactCTA analyticsSource={analyticsSource} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactDetails;
