/* storybook-check-ignore */
import { ReactNode } from 'react';

export enum TestimonialStatus {
  NoStatus,
  AppraisalMatched,
  WithdrewWithNoPenalty,
}

export const TESTIMONIAL_STATUS_DATE_INFO = {
  [TestimonialStatus.AppraisalMatched]: 'Closed on',
  [TestimonialStatus.WithdrewWithNoPenalty]: 'Withdrew on',
  [TestimonialStatus.NoStatus]: 'Closed on',
} as Record<TestimonialStatus, string>;

export type TestimonialProps = {
  id: string;
  snippet: ReactNode;
  fullText: ReactNode;
  status: TestimonialStatus;
  customer: { name: string; avatarUrl?: string; dateInfo: string };
  location?: string;
};

export const TESTIMONIALS: Array<TestimonialProps> = [
  {
    id: 'amanda-matasick',
    snippet: (
      <>
        I felt like I was shopping for a home on Amazon. It was great! It was ours and we didn't
        have to compete with anyone.
      </>
    ),
    fullText: (
      <>
        Our experience with Opendoor Exclusives could not have been better. We were so defeated
        after losing home after home to other offers. We had spent so much time searching for a
        home, driving to open houses, falling in love with homes, and coming up with the best offer
        we could possibly manage to not even be considered. It was exhausting and frustrating. We
        felt like we were just going to miss out on being homeowners for the foreseeable future due
        to circumstances that were far beyond our control. Home prices and interest rates were
        climbing and we couldn't keep up. After getting word that we had been passed over on another
        home that we had fallen in love with, along with word that our 2 backup homes were now off
        the market, I decided to open the email from Opendoor exclusives and see what it was all
        about. I was extremely skeptical and kept looking for the catch. It all seemed too good to
        be true. I found a home in our budget, in an area we wanted to live in, with enough square
        footage, and it had a pool. The pictures online looked like the home was in good shape.
        There was only 1 day left before it went on the market, so I pitched it to my husband. We
        were comfortable starting the process since Opendoor allowed us to walk away at any time
        with the ability to get our money back. I felt like I was shopping for a home on Amazon. It
        was great! It was ours and we didn't have to compete with anyone. We went under contract the
        next day and closed about 30 days later. The process was seamless and uncomplicated. I
        highly recommend it to anyone. We are thankful to Opendoor for giving us an opportunity to
        fairly purchase a home!
      </>
    ),
    customer: {
      name: 'Amanda Matasick',
      dateInfo: 'July 26, 2022',
    },
    status: TestimonialStatus.NoStatus,
    location: 'San Marcos, TX',
  },
  {
    id: 'kate-r',
    snippet: (
      <>
        We went from not being in the market to buy one week to being under contract on a home we
        loved the next!
      </>
    ),
    fullText: (
      <>
        My husband and I couldn't be happier with our experience purchasing our home with Opendoor
        Exclusives. We had a great experience with everyone we worked with and the process was super
        easy. We went from not being in the market to buy one week to being under contract on a home
        we loved the next! We had wanted to upgrade for some time, but didn't want to get into a
        bidding war and pay way over asking. Once you read about the program's benefits you may
        think it's too good to be true, but it's not! Outside of securing your financing it's as
        easy as going to the website, going to tour homes you want to see at your convenience, and
        signing your contract. We're very grateful that this program exists and are so happy with
        our new home!
      </>
    ),
    customer: {
      name: 'Kate R',
      dateInfo: 'June 28, 2022',
    },
    status: TestimonialStatus.AppraisalMatched,
    location: 'Austin, TX',
  },
  {
    id: 'michael-and-alla-degtyarev',
    snippet: (
      <>
        With Opendoor Exclusive Listings all the things that stressed us out about buying a house in
        a hot market like Austin were out of the question.
      </>
    ),
    fullText: (
      <>
        With Opendoor Exclusive Listings all the things that stressed us out about buying a house in
        the hot market like Austin were out of the question. There was no bidding war. There were no
        concerns about the appraisal with Appraisal Match Guarantee. We are very happy that we could
        benefit from the Exclusive Listings program by finding a place we call home now.
      </>
    ),
    customer: {
      name: 'Michael and Alla Degtyarev',
      dateInfo: 'May 3, 2022',
    },
    status: TestimonialStatus.AppraisalMatched,
    location: 'Austin, TX',
  },
  {
    id: 'joe-godina',
    snippet: (
      <>
        We’ve heard of the term picture-perfect, that’s how I’d describe the Opendoor Exclusive
        experience.
      </>
    ),
    fullText: (
      <>
        We’ve heard of the term picture-perfect, that’s how I’d describe the Opendoor Exclusive
        experience. The whole process was great from the moment we saw the home to the time we
        closed, which took maybe 6-7 weeks. I never expected it to close that quickly I've heard
        friends say it takes 3 months or longer to close. Even my realtor was shocked that Opendoor
        honored the Appraisal Match.
      </>
    ),
    customer: {
      name: 'Joe Godina',
      dateInfo: 'May 3, 2022',
    },
    status: TestimonialStatus.AppraisalMatched,
    location: 'Houston, TX',
  },
  {
    id: 'colton-katzen',
    snippet: (
      <>
        We decided to try Opendoor Exclusives and it could not have been a smoother process from
        beginning to end.
      </>
    ),
    fullText: (
      <>
        We found a beautiful home in a great neighborhood, but didn’t want to get our hopes up.
        After going through this four other times only to ultimately miss out on homes that went
        well above asking, we were skeptical to move forward. We decided to try Opendoor Exclusives
        and it could not have been a smoother process from beginning to end. There was no stressing,
        no haggling, no worrying about an appraisal — it was actually an enjoyable experience! The
        only thing I would change is starting our search with Opendoor.
      </>
    ),
    customer: {
      name: 'Colton Katzen',
      dateInfo: 'May 5, 2022',
    },
    status: TestimonialStatus.NoStatus,
    location: 'Austin, TX',
  },
];
