/* storybook-check-ignore */
import React from 'react';

import { Box, BoxProps } from '@opendoor/bricks/core';
import { ITheme } from '@opendoor/bricks/system/theme';
import { eero, exclusives } from '@opendoor/bricks/theme';
import isUndefined from 'lodash/isUndefined';

import Headerv2, { HeaderV2Props } from 'components/exclusives/layout/Headerv2/Headerv2';
import { BaseLayout, BaseLayoutProps } from 'components/exclusives/layouts/BaseLayout';

import { FrontChatProps } from '../layout/FrontChat';
import MarketSelectorModal from '../MarketSelectorModal';

export type DefaultLayoutProps = FrontChatProps &
  Pick<BaseLayoutProps, 'description' | 'title'> & {
    region?: string | null;
    hideHeaderNavLinks?: boolean;
    headerMaxWidth?: BoxProps['maxWidth'];
    openLinkOnNewTab?: boolean;
    shouldShowSearch?: boolean;
    containerProps?: BoxProps;
    shouldUseEeroBreakpoints?: boolean;
    headerProps?: HeaderV2Props;
    Header?: React.ReactNode;
    loadHotjar?: boolean;
    breakpoints?: ITheme['breakpoints'];
    theme?: 'eero' | 'novo' | 'legacy' | 'exclusives';
  } & BoxProps;

const DefaultLayout = ({
  breakpoints,
  children,
  containerProps,
  description,
  Header,
  headerMaxWidth,
  headerProps,
  hideFrontChat,
  hideBubble,
  hideHeaderNavLinks,
  loadHotjar,
  openLinkOnNewTab,
  region,
  title = 'Opendoor',
  shouldShowSearch,
  shouldUseEeroBreakpoints,
  theme,
  ...restOfProps
}: DefaultLayoutProps) => {
  return (
    <BaseLayout
      description={description}
      hasHotjarScript={loadHotjar}
      hasMetaPixelScript
      hideFrontChat={hideFrontChat}
      hideBubble={hideBubble}
      theme={theme}
      themeOverrides={{
        // TODO(@exclusives): should work to ensure ALL pages are on the new breakpoints
        // rather than on legacy breakpoints (exclusives.breakpoints or when the theme
        // is just set to `exclusives`)
        breakpoints: shouldUseEeroBreakpoints ? eero.breakpoints : exclusives.breakpoints,
        // TODO(@exclusives): Remove custom breakpoints
        ...(breakpoints && { breakpoints }),
      }}
      title={title}
    >
      {!isUndefined(Header) ? Header : <Headerv2 {...headerProps} />}
      <Box {...restOfProps} {...containerProps}>
        {children}
      </Box>
      <MarketSelectorModal />
    </BaseLayout>
  );
};

export default DefaultLayout;
